<style lang="less" scoped>
.contanter{
  width: 100%;
  min-width: 1600px;
  height: 735px;
  background-image: url("https://resource.jiupiao.vip/feedback/20240207/170730283106053.png");
  background-size: 100% 735px;
  background-repeat: no-repeat;
  background-position: center;
  .body{
    display: flex;
    width: 1600px;
    margin: 0 auto;
    padding: 256px 0;
    justify-content: center;
    .left{
      text-align: center;
      margin-right: 70px;
      img{
        display: block;
        margin: 0 auto;
      }
      .logo{
        width: 310px;
        height: 175px;
        margin-bottom: 16px;
      }
      .title{
        width: 263px;
        height: 23px;
        margin-bottom: 19px;
      }
      .smtitle{
        width: 209px;
        height: 18px;
        margin-bottom: 19px;
      }
      .list{
        display: flex;
        justify-content: center;
        .icon{
          width: 43px;
          height: 43px;
          margin: 0 4px;
        }
      }
    }
    .right{
      padding-top: 75px;
      .top{
        display: flex;
        justify-content: left;
        width: 664px;
        border-bottom: 1px solid #FFDDA3;
        padding-bottom: 14px;
        .leftBox{
          margin-right: 43px;
          .title{
            color: #FFDDA3;
            font-size: 21px;
            font-weight: bold;
          }
          .list{
            color: #fff;
            font-size: 15px;
            line-height: 1.5;
            margin-left: 15px;
          }
          .list::before{
            content: '';
            width: 8px;
            height: 8px;
            background: #FFDDA3;
            border-radius: 8px;
            display: block;
            position: relative;
            top: 15px;
            right: 15px;
          }
        }
        .rightBox{
          .title{
            color: #FFDDA3;
            font-size: 21px;
            font-weight: bold;
          }
          .code{
            width: 79px;
            height: 79px;
            margin-top: 16px;
          }
        }
      }
      .btm{
        display: flex;
        justify-content: right;
        margin-top: 14px;
        .menu-item{
          margin-left: 39px;
          color: #fff;
          font-weight: bold;
          font-size: 18px;
          .menu-title{
          }
        }
        .active{
          color: #FFDDA3!important;
          font-weight: bold;
        }
      }
    }
  }
}
</style>

<template>
  <div class="contanter">
    <div class="body">
      <div class="left">
        <img src="https://resource.jiupiao.vip/feedback/20240207/1707302879311120.png" alt="" class="logo">
      </div>
      <div class="right">
        <div class="top">
          <div class="leftBox">
            <div class="title">公司地址</div>
            <div class="list" @click="beian">备案号：陕ICP备XXXXXXXXX号-1</div>
            <div class="list">上海市宝山区山连路779号</div>
          </div>
        </div>
        <div class="btm">
          <div class="menu-item" v-for="item in page" :key="item" @click="goPage(item.link)">
            <span class="menu-title" :class="{ active: currentPath === item.link }">{{ item.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from "@/router";
import {ref, watchEffect} from "vue";

const page = [
  {
    title: '关于我们',
    link: '/',
  },
  {
    title: '产品中心',
    link: '/industry',
  },
  {
    title: '商务合作',
    link: '/contactUs',
  },
]
const currentPath = ref('');
const goPage = (link) => {
  router.push(link);
  currentPath.value = link;
  console.log(currentPath.value)
}
const beian = () => {
  window.open('https://beian.miit.gov.cn/', '_blank');
}
watchEffect(() => {
  currentPath.value = window.location.hash.replace('#', '');
});
</script>

